import React, { useCallback } from "react";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { GroceryEditItemScreenProps } from "../navigation/NavTree";
import { GroceryListItemEdit } from "../components/grocery/GroceryListItemEdit";

const strings = {
  screenTitle: "Edit item",
};

export const GroceryEditItemScreen = withNonNavigableScreenContainer<GroceryEditItemScreenProps>(
  "GroceryEditItemScreen",
  (props: GroceryEditItemScreenProps) => {
    return React.createElement<Props>(GroceryEditItemScreenComponent, props);
  }
);

interface Props {
  itemId: GroceryListItemId;
}

const GroceryEditItemScreenComponent = React.memo((props: Props) => {
  const screen = useScreen();

  const goBack = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={"headerOnly"}
      header={{
        type: "default",
        title: strings.screenTitle,
        backgroundColor: "transparent",
        right: { type: "done", onPress: goBack },
      }}
      isModal
    >
      <GroceryListItemEdit itemId={props.itemId} onDismiss={goBack} />
    </ScreenView>
  );
});
