import { bottomThrow } from "@eatbetter/common-shared";
import {
  ScalingCompoundAdditiveMeasurement,
  ScalingMeasurementWithAlternates,
  ScalingQuantity,
  ScalingQuantityUnit,
  UnitConversion,
} from "@eatbetter/items-shared";
import { ConvertedUnit, scaleAndConvertQuantityUnit } from "./ScaleAndConvert";
import { formatQuantity, formatQuantityUnit } from "./Format";
import { fractionConfigs } from "./UnitMetadata";

export interface ChangedString {
  indices: [number, number];
  text: string;
}

export function getQuantityUnitString(args: {
  qu: ScalingQuantityUnit;
  scale: number;
  conversion: UnitConversion;
}): ChangedString[] {
  const quScaledConverted = scaleAndConvertQuantityUnit({ ...args, conversionType: "best" });

  const formatted = formatQuantityUnit(quScaledConverted, quScaledConverted.originalUnit);
  return [...formatted.q, ...(formatted.u ? [formatted.u] : [])];
}

export function getAdditiveQuantityUnitString(args: {
  additive: ScalingCompoundAdditiveMeasurement;
  scale: number;
  conversion: UnitConversion;
}): ChangedString {
  // First, convert all measurements to the base unit for the given system
  const scaledAndConverted = args.additive.measurements.map(i =>
    scaleAndConvertQuantityUnit({ qu: i, scale: args.scale, conversion: args.conversion, conversionType: "base" })
  );

  let q: ScalingQuantity[];

  // These ranges are arbitrary since we're going to replace the entire set of ranges, but in order for the
  // qu spacing logic to trigger correctly, we set them such that there is no space between them (see Format.ts).
  const qRange: [number, number] = [0, 0];
  const uRange: [number, number] = [1, 1];

  // Check if some of the quantities have ranges and if so, reduce to a single range representing the sum of all
  // ranges + values.
  if (scaledAndConverted.some(i => i.q.length > 1)) {
    const qMin = scaledAndConverted
      .map(i => i.q.reduce((min, curr) => (curr.value < min.value ? curr : min)))
      .reduce((sum, curr) => sum + curr.value, 0);

    const qMax = scaledAndConverted
      .map(i => i.q.reduce((max, curr) => (curr.value > max.value ? curr : max)))
      .reduce((sum, curr) => sum + curr.value, 0);

    q = [
      {
        value: qMin,
        range: qRange,
      },
      {
        value: qMax,
        range: qRange,
      },
    ];
  } else {
    // If it's just single quantities, add them up.
    const qSum = scaledAndConverted.flatMap(i => i.q).reduce((sum, curr) => sum + curr.value, 0);

    q = [
      {
        value: qSum,
        range: qRange,
      },
    ];
  }

  // Since we replace the entire string, replace the individual ranges with a single one
  const indices: [number, number] = args.additive.measurements.reduce(
    (acc, curr) => {
      const currRangeValues = [...curr.q.flatMap(i => i.range), ...(curr.u?.range ?? [])].sort((a, b) => a - b);

      const currMin = currRangeValues[0];
      const currMax = currRangeValues.at(-1);

      const newMin = currMin !== undefined && currMin < acc[0] ? currMin : acc[0];
      const newMax = currMax !== undefined && currMax > acc[1] ? currMax : acc[1];

      return [newMin, newMax];
    },
    [Number.MAX_SAFE_INTEGER, 0]
  );

  const first = scaledAndConverted[0];

  const u: ConvertedUnit | undefined = first?.u ? { ...first.u, range: uRange } : undefined;

  // Construct a QU with the processed quantity and unit
  const qu: ScalingQuantityUnit = { type: "qu", q, u };

  // Now convert to the appropriate unit
  const quBestConverted = scaleAndConvertQuantityUnit({ qu, scale: 1, conversion: "original", conversionType: "best" });

  const formatted = formatQuantityUnit(quBestConverted, first?.originalUnit);

  // Join quantity ranges with a dash
  const qString = formatted.q.map(i => i.text).join("-");
  const uString = formatted.u?.text ?? "";

  // Simple concat as all spacing logic takes place in the formatters
  const quString = `${qString}${uString}`;

  return { text: quString, indices };
}

export function getAlternatesQuantityUnitString(args: {
  qu: ScalingMeasurementWithAlternates;
  scale: number;
  conversion: UnitConversion;
}): ChangedString[] {
  return args.qu.measurements.flatMap(i => {
    // if the equivalent measurement is already present in the alternates, don't convert - only scale
    const conversion = i.convertedEquivalentIndex !== undefined ? "original" : args.conversion;
    switch (i.type) {
      case "additive": {
        return [getAdditiveQuantityUnitString({ additive: i, scale: args.scale, conversion })];
      }
      case "qu": {
        return getQuantityUnitString({ qu: i, scale: args.scale, conversion });
      }
      default:
        bottomThrow(i);
    }
  });
}

/**
 * Returns a UI-friendly formatted string for the given scale value
 */
export function getScaleValueDisplay(scale: number): string {
  return formatQuantity({ quantity: scale, ...fractionConfigs.all }).text;
}
