import React from "react";
import { View } from "react-native";
import { Text, TextProps } from "../Typography";
import { Opacity } from "../GlobalStyles";
import { RecipeYield } from "@eatbetter/recipes-shared";
import { useScaled } from "../../lib/recipes/UseScaled";
import { UnitConversion } from "@eatbetter/items-shared";
import { ModifiableRecipeText, ModifiableRecipeTextProps } from "./RecipeText";

const strings = {
  recipeYield: "Yield",
};

interface Props {
  yield: RecipeYield;
  recipeScale: number;
  recipeUnits: UnitConversion;
  fontSize?: ModifiableRecipeTextProps["fontSize"];
  italic?: boolean;
  opacity?: Extract<keyof typeof Opacity, "dark" | "opaque">;
}

export const RecipeYieldDisplay = React.memo((props: Props) => {
  const tokens = useScaled(props.yield, props.recipeScale, props.recipeUnits);

  const textProps: Pick<TextProps, "opacity" | "italic" | "numberOfLines"> & Required<Pick<Props, "fontSize">> = {
    fontSize: props.fontSize ?? "secondary",
    opacity: props.opacity ?? "opaque",
    italic: props.italic,
  };

  return (
    <View>
      <Text fontSize={textProps.fontSize} numberOfLines={1}>
        <Text {...textProps} fontSize={textProps.fontSize}>{`${strings.recipeYield}: `}</Text>
        <ModifiableRecipeText
          {...textProps}
          fontSize={textProps.fontSize}
          tokens={tokens}
          originalText={props.yield.text}
        />
      </Text>
    </View>
  );
});
