import { bottomThrow } from "@eatbetter/common-shared";
import { PreciseUnit } from "./PreciseUnit";
import { FractionDisplayKey, getFractionSet } from "./Fractions";

export interface UnitMetadata {
  system: "standard" | "metric";
  measurementType: "mass" | "volume" | "length" | "temperature";
  allowedFractions?: Array<FractionDisplayKey>;
  fractionMatchThreshold?: number;
  roundingThreshold?: number | [zero: number, one: number];
}

export const fractionConfigs = {
  quarterFractions: {
    allowedFractions: getFractionSet("quarters"),
    fractionMatchThreshold: 1 / 4,
    roundingThreshold: 1 / 8,
  },
  standardFractions: {
    allowedFractions: getFractionSet("standard"),
    fractionMatchThreshold: 1 / 4,
    roundingThreshold: 1 / 8,
  },
  quarterWithEighthFractions: {
    allowedFractions: [...getFractionSet("quarters"), "1/8"],
    fractionMatchThreshold: 1 / 4,
    roundingThreshold: [1 / 16, 1 / 8],
  },
  all: {
    allowedFractions: getFractionSet("all"),
    fractionMatchThreshold: 1 / 4,
    roundingThreshold: [1 / 16, 1 / 8],
  },
} satisfies Record<string, Partial<UnitMetadata>>;

const unitMetadata: { [K in PreciseUnit]: UnitMetadata } = {
  // mass
  milligram: { measurementType: "mass", system: "metric" },
  gram: { measurementType: "mass", system: "metric" },
  kilogram: { measurementType: "mass", system: "metric" },
  ounce: {
    ...fractionConfigs.quarterFractions,
    measurementType: "mass",
    system: "standard",
  },
  pound: {
    ...fractionConfigs.quarterFractions,
    measurementType: "mass",
    system: "standard",
  },

  // volume
  milliliter: { measurementType: "volume", system: "metric" },
  deciliter: { measurementType: "volume", system: "metric" },
  liter: { measurementType: "volume", system: "metric" },
  "US fluid ounce": {
    measurementType: "volume",
    system: "standard",
    ...fractionConfigs.quarterWithEighthFractions,
  },
  teaspoon: { measurementType: "volume", system: "standard", ...fractionConfigs.quarterWithEighthFractions },
  tablespoon: {
    measurementType: "volume",
    system: "standard",
    ...fractionConfigs.quarterFractions,
  },
  cup: { measurementType: "volume", system: "standard", ...fractionConfigs.standardFractions },
  pint: { measurementType: "volume", system: "standard", ...fractionConfigs.quarterFractions },
  quart: { measurementType: "volume", system: "standard", ...fractionConfigs.quarterFractions },
  gallon: { measurementType: "volume", system: "standard", ...fractionConfigs.quarterFractions },

  // length
  inch: { measurementType: "length", system: "standard", ...fractionConfigs.quarterWithEighthFractions },
  foot: { measurementType: "length", system: "standard", ...fractionConfigs.quarterFractions },
  yard: { measurementType: "length", system: "standard", ...fractionConfigs.quarterFractions },
  centimeter: { measurementType: "length", system: "metric" },
  millimeter: { measurementType: "length", system: "metric" },
  meter: { measurementType: "length", system: "metric" },

  // temperature
  celsius: { measurementType: "temperature", system: "metric" },
  fahrenheit: { measurementType: "temperature", system: "standard" },
};

export function getUnitMetadata(unit?: PreciseUnit): UnitMetadata {
  if (!unit) {
    return {
      // measurementType and system are arbitrary here but avoids the need to loosen types for all other cases
      measurementType: "mass",
      system: "standard",
      ...fractionConfigs.all,
    };
  }
  return unitMetadata[unit];
}

export function getBaseUnit(
  measurementType: (typeof unitMetadata)[PreciseUnit]["measurementType"],
  system: (typeof unitMetadata)[PreciseUnit]["system"]
): PreciseUnit {
  switch (measurementType) {
    case "mass": {
      return system === "standard" ? "ounce" : "gram";
    }
    case "volume": {
      return system === "standard" ? "teaspoon" : "milliliter";
    }
    case "length": {
      return system === "standard" ? "inch" : "centimeter";
    }
    case "temperature": {
      return system === "standard" ? "fahrenheit" : "celsius";
    }
    default:
      bottomThrow(measurementType);
  }
}
