import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback, useRef } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { useSurveyOptions } from "../../components/SurveyOptions";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { View } from "react-native";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";
import { navTree } from "../../navigation/NavTree";
import { Haptics } from "../../components/Haptics";
import { shuffle } from "lodash";

const strings = {
  headline: "How did you discover Deglaze?",
  subhead: "Your insights help us keep Deglaze free.",
  otherLabel: "How did you discover Deglaze?",
  otherInputPlaceholder: "Tell us how we met...",
};

const surveyOptionKeys = ["instagram", "facebook", "reddit", "google", "appStore", "friendFamily", "other"] as const;

type SurveyOptionKey = (typeof surveyOptionKeys)[number];

const surveyOptionDisplay: Record<SurveyOptionKey, string> = {
  instagram: "Instagram",
  facebook: "Facebook",
  reddit: "Reddit",
  google: "Google",
  appStore: "Apple App Store",
  friendFamily: "Friends & Family",
  other: "Other",
};

const surveyOptions = surveyOptionKeys.map(key => ({
  key,
  displayText: surveyOptionDisplay[key],
}));

export const OnboardingDiscoverySourceScreen = withNonNavigableScreenContainer(
  "OnboardingDiscoverySourceScreen",
  () => {
    const { nav } = useScreen();
    const dispatch = useDispatch();

    // Randomize options only on initial render
    const randomizedSurveyOptions = useRef(randomizeSurveyOptions(surveyOptions));

    const surveyOptionsState = useSurveyOptions("singleSelect", randomizedSurveyOptions.current);

    const next = useCallback(
      (otherText?: string) => {
        if (!otherText) {
          // Already called from the onSubmitOther callback (for instant feedback), so only call if it's not other here
          Haptics.feedback("itemStatusChanged");
        }

        const discoverySource = surveyOptionsState.selected[0]?.key;

        dispatch(
          onboardingQuestionAnswered({
            discoverySource,
            discoverySourceOtherText: otherText,
            discoverySourceSurveyIndex: randomizedSurveyOptions.current.findIndex(i => i.key === discoverySource),
          })
        );
        dispatch(navToNextOnboardingScreen(nav, "replace"));
      },
      [dispatch, surveyOptionsState.selected]
    );

    const onSubmitOther = useCallback(
      (otherText: string) => {
        Haptics.feedback("itemStatusChanged");
        setTimeout(() => {
          next(otherText);
        }, 300);
      },
      [next]
    );

    const onNext = useCallback(() => {
      if (surveyOptionsState.selected[0]?.key === "other") {
        nav.modal(navTree.get.screens.surveyOtherOptionForm, {
          label: strings.otherLabel,
          textInputPlaceholder: strings.otherInputPlaceholder,
          onSubmit: onSubmitOther,
        });
      } else {
        next();
      }
    }, [surveyOptionsState.selected, nav.modal, next]);

    return (
      <ScreenView scrollView={false} header={onboardingScreenHeader}>
        <View
          style={{
            flex: 1,
            paddingBottom: bottomActionBarConstants.height,
            justifyContent: "space-between",
          }}
        >
          <View>
            <OnboardingScreenHeadline headline={strings.headline} subHeadline={strings.subhead} />
          </View>
          <View>{surveyOptionsState.renderSurveyOptions()}</View>
        </View>
        <OnboardingCtaButton
          cta="next"
          background="transparent"
          disabled={surveyOptionsState.selected.length === 0}
          onPress={onNext}
        />
      </ScreenView>
    );
  }
);

/**
 * Randomizes while keeping social options together and "other" at the end
 */
function randomizeSurveyOptions(
  surveyOptions: ReadonlyArray<{ key: SurveyOptionKey; displayText: string }>
): Array<{ key: SurveyOptionKey; displayText: string }> {
  const socialKeys: SurveyOptionKey[] = ["instagram", "facebook", "reddit"];
  const socialOptions = surveyOptions.filter(option => socialKeys.includes(option.key));
  const remainingOptions = surveyOptions.filter(option => !socialKeys.includes(option.key) && option.key !== "other");
  const otherOption = surveyOptions.find(option => option.key === "other");

  const randomizedSocialOptions = shuffle(socialOptions);
  const randomizedRemainingOptions = shuffle(remainingOptions);

  const insertionIndex = Math.floor(Math.random() * (randomizedRemainingOptions.length + 1));

  const optionsWithSocial = [
    ...randomizedRemainingOptions.slice(0, insertionIndex),
    ...randomizedSocialOptions,
    ...randomizedRemainingOptions.slice(insertionIndex),
  ];

  const finalOptions = otherOption ? [...optionsWithSocial, otherOption] : optionsWithSocial;

  return finalOptions;
}
