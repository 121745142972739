import { useMemo } from "react";
import { bottomThrow, charLength } from "@eatbetter/common-shared";
import { ScalingMeasurement, ScalingAndConversionInfo, UnitConversion } from "@eatbetter/items-shared";
import { RecipeInstruction } from "@eatbetter/recipes-shared";
import { CookingSessionInstructionTimer } from "@eatbetter/cooking-shared";
import {
  getAdditiveQuantityUnitString,
  getAlternatesQuantityUnitString,
  getQuantityUnitString,
} from "./conversions/QuantityUnitDisplay";
import { log } from "../../Log";

export interface RecipeTextToken {
  type: "text";
  isModified: boolean;
  text: string;
  /**
   * the indices of the original (unmodified) string that relate to the text)
   */
  range: [number, number];
}

export interface CookingTimerText {
  type: "timer";
  timer: CookingSessionInstructionTimer;
  text: string;
  /**
   * the indices of the original (unmodified) string that relate to the text)
   */
  range: [number, number];
}

export interface ScalableText {
  text: string;
  scaling?: ScalingAndConversionInfo;
}

export const useScaled = (
  i: ScalableText | undefined,
  scale: number,
  conversion: UnitConversion
): RecipeTextToken[] => {
  return useMemo(() => {
    if (!i) {
      return [];
    }
    return scaleAndConvertRecipeText(i, scale, conversion);
  }, [i, scale, conversion]);
};

export const useScaledInstruction = (
  i: RecipeInstruction | undefined,
  timers: CookingSessionInstructionTimer[],
  scale: number,
  conversion: UnitConversion
): Array<RecipeTextToken | CookingTimerText> => {
  return useMemo(() => {
    if (!i) {
      return [];
    }

    return scaleInstructionWithTimers(i, timers, scale, conversion);
  }, [i, timers, scale, conversion]);
};

export function scaleInstructionWithTimers(
  instruction: RecipeInstruction,
  timers: CookingSessionInstructionTimer[],
  scale: number,
  conversion: UnitConversion
): Array<RecipeTextToken | CookingTimerText> {
  const scaled = scaleAndConvertRecipeText(instruction, scale, conversion);

  // if there are no timers, we're done
  if (timers.length === 0) {
    return scaled;
  }

  // if there are timers, ditch the unmodified text to keep things simple, then sort by the ranges
  const sorted = [...scaled.filter(s => s.isModified), ...timers].sort((a, b) => {
    // sort by start of range and then by end of range
    if (a.range[0] !== b.range[0]) {
      return a.range[0] - b.range[0];
    }

    return a.range[1] - b.range[1];
  });

  // rebuild
  const text = instruction.text;
  // account for emoji and other double-width characters
  const overallLen = charLength(instruction.text);
  const tokens: Array<RecipeTextToken | CookingTimerText> = [];
  let currentIndex = 0;
  for (const t of sorted) {
    const prev = tokens.at(-1);
    if (prev && t.range[0] <= prev.range[1]) {
      // overlapping tokens. Keep the first and move on
      continue;
    }

    const before = text.substring(currentIndex, t.range[0]);
    if (before.length > 0) {
      tokens.push({ type: "text", text: before, isModified: false, range: [currentIndex, t.range[0] - 1] });
    }

    if (t.type === "text") {
      tokens.push(t);
    } else {
      tokens.push({ type: "timer", timer: t, range: t.range, text: text.substring(t.range[0], t.range[1] + 1) });
    }

    currentIndex = t.range[1] + 1;
  }

  const after = text.substring(currentIndex);
  if (after.length > 0) {
    tokens.push({ type: "text", text: after, isModified: false, range: [currentIndex, overallLen - 1] });
  }

  return tokens;
}

export function scaleAndConvertRecipeText(
  i: ScalableText,
  scale: number,
  conversion: UnitConversion
): Array<RecipeTextToken> {
  // account for emoji and other double-width characters
  const overallLen = charLength(i.text);

  try {
    const qu = i.scaling?.measurements ?? [];

    if ((scale === 1 && conversion === "original") || qu.length === 0) {
      return [{ type: "text", text: i.text, isModified: false, range: [0, overallLen - 1] }];
    }

    const changes = qu.flatMap(m => getChangedValues(m, m.scale ? scale : 1, m.noConvert ? "original" : conversion));

    const mt: RecipeTextToken[] = [];
    const text = i.text;

    let currentIndex = 0;
    changes.forEach(change => {
      const originalText = i.text.substring(change.indices[0], change.indices[1] + 1);
      if (change.text === originalText) {
        // Skip modified entries where the output equals exactly the input. This prevents converted but not scaled entries
        // such as temperature from getting highlighted even though it wasn't modified.
        return;
      }

      const before = text.substring(currentIndex, change.indices[0]);
      if (before.length > 0) {
        mt.push({ type: "text", text: before, isModified: false, range: [currentIndex, change.indices[0] - 1] });
      }
      mt.push({
        type: "text",
        text: change.text,
        isModified: true,
        range: change.indices,
      });
      currentIndex = change.indices[1] + 1;
    });

    const after = text.substring(currentIndex);
    if (after.length > 0) {
      mt.push({ type: "text", text: after, isModified: false, range: [currentIndex, overallLen - 1] });
    }

    return mt;
  } catch (err) {
    log.errorCaught("scaleRecipeText(): unexpected error caught. Returning unmodified text", err, {
      i,
      scale,
      conversion,
    });
    return [{ type: "text", text: i.text, isModified: false, range: [0, overallLen - 1] }];
  }
}

interface ChangedString {
  indices: [number, number];
  text: string;
}

function getChangedValues(m: ScalingMeasurement, scale: number, conversion: UnitConversion): Array<ChangedString> {
  switch (m.type) {
    case "qu":
      return getQuantityUnitString({ qu: m, scale, conversion });
    case "additive":
      return [getAdditiveQuantityUnitString({ additive: m, scale, conversion })];
    case "alternates":
      return getAlternatesQuantityUnitString({ qu: m, scale, conversion });
    default:
      bottomThrow(m);
  }
}
