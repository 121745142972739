import convert, { BestKind } from "convert";
import { PreciseUnit, toPreciseUnit } from "./PreciseUnit";
import { UnitConversion } from "@eatbetter/items-shared";
import { switchReturn } from "@eatbetter/common-shared";

type UnitConversionSystem = Exclude<UnitConversion, "original">;

export interface ConvertedQuantityUnit {
  quantity: number;
  unit: PreciseUnit;
}

/**
 * Convert a quantity (number) from a given unit to a new specified unit
 */
export function convertToUnit(quantity: number, fromUnit: PreciseUnit, toUnit: PreciseUnit): number {
  const converted = convert(quantity, fromUnit).to(toUnit);

  return converted;
}

/**
 * Convert a quantity + unit to the best unit of the specified system (metric/imperial)
 */
export function convertUnit(
  quantity: number,
  unit: PreciseUnit,
  convertTo: UnitConversionSystem
): ConvertedQuantityUnit {
  const convertToInternal = switchReturn<UnitConversionSystem, BestKind>(convertTo, {
    standard: "imperial",
    metric: "metric",
  });

  const converted = convert(quantity, unit).to("best", convertToInternal);
  const convertedUnit = toPreciseUnit(converted.unit);

  if (!convertedUnit) {
    return {
      quantity,
      unit,
    };
  }

  const bestConversion = convertToBestUnit(converted.quantity, convertedUnit);

  return bestConversion;
}

// This is where we adjust the "best" conversion of the convertjs library to better match our domain
function convertToBestUnit(quantity: number, unit: PreciseUnit): ConvertedQuantityUnit {
  switch (unit) {
    case "milligram": {
      const bestUnit: PreciseUnit = "gram";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    case "ounce": {
      const bestUnit: PreciseUnit = quantity < 4 ? "ounce" : "pound";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    case "pound": {
      const bestUnit: PreciseUnit = quantity < 0.25 ? "ounce" : "pound";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    case "US fluid ounce": {
      const bestUnit: PreciseUnit = quantity < 2 ? "tablespoon" : "cup";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    case "pint":
    case "quart": {
      const bestUnit: PreciseUnit = "cup";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    case "yard": {
      const bestUnit: PreciseUnit = "inch";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    case "foot": {
      const bestUnit: PreciseUnit = "inch";
      const converted = convertToUnit(quantity, unit, bestUnit);
      return { quantity: converted, unit: bestUnit };
    }
    default: {
      return { quantity, unit };
    }
  }
}
